.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}


.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    /* height: 13rem; */
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 1.2rem;
    background: transparent;
}


.portfolio_description {
    font-size: 0.7em;
    font-weight: lighter;
    margin: 0 0 2rem 0;
}

.portfolio_description_and_cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.portfolio__item h3 {
    border-radius: 1.5rem;
    margin: 1.2rem 0 0.5rem;
}


.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}




/* =================== MEDIA QUERIES (LARGE DEVICES) =============*/

@media screen and (min-width: 1025px) {}

/* =================== MEDIA QUERIES (MEDIUM DEVICES) =============*/


@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* =================== MEDIA QUERIES (SMALL DEVICES) =============*/

@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}