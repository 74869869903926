header {
    padding-top: 1.5rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    position: relative;
}


/* =============== CTA =============== */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


/* =============== HEADER SOCIALS =============== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 8rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    /* height: 2rem; */
    background: var(--color-primary);
}

/* =============== ME =============== */

/* .me {
} */
/* 
.me_container{
    padding-top: 1rem;
    background-color: var(--color-primary);
} */

.me {
    padding-top: 0rem;
    background-image: linear-gradient(to bottom, var(--color-primary),rgba(77, 181, 255,0.0 ));
    border-radius: 50% 50% 0.0rem 0.0rem;
    width: 20rem;
    margin-top: 2rem;
    padding-top: 0rem;
    margin-right: auto;
    margin-left: auto;
}

.image_profile {
    padding: 0.0rem;
    padding-top: 0rem;
    
}



/* =============== HEADER =============== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 10rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}



/* =================== MEDIA QUERIES (LARGE DEVICES) =============*/

@media screen and (min-width: 1025px) {
    
}
/* =================== MEDIA QUERIES (MEDIUM DEVICES) =============*/


@media screen and (max-width: 1024px) {
    .container {
        background-color: transparent;
        /* height: 68vh; */
    }


    header {
        overflow: hidden;
    }
    
}

/* =================== MEDIA QUERIES (SMALL DEVICES) =============*/

@media screen and (max-width: 600px) {
    .container {
        background-color: transparent;
        /* height: 100vh; */
    }
    .header__socials, .scroll__down {
        display: none;
    }

    
    
}